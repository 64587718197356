import React from 'react';
import {
  AppBar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    alignSelf: 'center',
  },
  bar: {
    backgroundColor: '#029C4F',
  },
}));

export const TopBar: React.FC = () => {
  const classes = useStyles();
  return (
    <AppBar id="app-bar" className={classes.bar} position="static">
      <Typography id="app-bar__title" variant="h3" className={classes.title}>
        Todo App
      </Typography>
    </AppBar>
  );
};

export default TopBar;
