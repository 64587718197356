import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import MainPage from './MainPage';

const App: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/" component={MainPage} />
      <Route path="/api-docs" />
    </Switch>
  </Router>
);

export default App;
