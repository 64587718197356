import React, {useEffect, useState} from 'react';
import {
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton, makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { IListTodo } from '../../interfaces';

const useStyles = makeStyles(() => ({
  rootTypography: {
    wordWrap: 'break-word',
    maxWidth: 360,
  },
}));

const ListTodos: React.FC<IListTodo> = ({
  handleToggle, handleDelete, handleOpen, todo,
}: IListTodo) => {
  const classes = useStyles();
  const labelId = `${todo?.id}`;

  console.log('🤖todo', todo);

  const [createdDate] = useState<string>(
    Intl.DateTimeFormat('ru',
      {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(todo?.createdAt)),
  );
  const [updatedDate, setUpdatedDate] = useState<string>(
    Intl.DateTimeFormat('ru',
      {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(todo?.updatedAt)),
  );

  useEffect(() => {
    setUpdatedDate(
      Intl.DateTimeFormat('ru',
        {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(todo?.updatedAt)),
    );
  }, [todo]);

  return (
    <ListItem id={`list-item-${labelId}`}>
      <ListItemIcon id={`list-item-${labelId}__checkbox`}>
        <Checkbox
          edge="start"
          checked={todo?.status}
          id={labelId}
          onClick={handleToggle}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={<Typography className={classes.rootTypography} display="block" variant="body1">{todo?.text}</Typography>}
        onDoubleClick={() => handleOpen(todo.id)}
        secondary={(
          <>
            <Typography
              variant="caption"
            >
              Created at
              {' '}
              {createdDate}
            </Typography>
            <br />
            <Typography
              variant="caption"
            >
              Updated at
              {' '}
              {updatedDate}
            </Typography>
          </>
        )}
      />
      <IconButton id={`list-item-${labelId}__delete`} edge="end" onClick={() => handleDelete(todo.id)}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

export default ListTodos;
