import React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { IDialogEdit } from '../../interfaces';
import { ENTER_KEY } from '../../constants';

const DialogEdit: React.FC<IDialogEdit> = ({
  open,
  handleEdit,
  handleClose,
  todoForEdit,
  handleEditSubmit,
  helperText,
  codeOfAxiosResponse,
}: IDialogEdit) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { keyCode } = event;
    if (keyCode === ENTER_KEY) {
      handleEditSubmit();
    }
  };

  return (
    <>
      <Dialog id="form-dialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog__title">Edit todo</DialogTitle>
        <DialogContent id="form-dialog__content">
          <TextField
            multiline
            error={!!codeOfAxiosResponse}
            helperText={helperText}
            autoFocus
            margin="dense"
            id="editTodo"
            label="New text"
            defaultValue={todoForEdit?.text}
            type="text"
            fullWidth
            onChange={handleEdit}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions id="form-dialog__action actions">
          <Button id="actions__cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button id="actions__edit" onClick={handleEditSubmit} color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEdit;
