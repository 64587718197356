import React, {
  ChangeEvent, FormEvent, FC, useCallback, useState, useEffect,
} from 'react';
import {
  TextField,
  Button,
  List,
  makeStyles,
  Paper,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import axios, { AxiosError } from 'axios';
import TopBar from './TopBar';
import ListTodos from './ListTodos';
import DialogEdit from './Dialog';
import { IMainPage, ITodo } from '../interfaces';
import handlerErrors from '../utils/handlerErrors';

import './MainPage.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginTop: theme.spacing(1),
      width: '25ch',
    },
  },
  todo: {
    display: 'flex',
    justifyContent: 'center',
  },
  rootTodo: {
    position: 'relative',
    top: '0.5vh',
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  rootTab: {
    flexGrow: 1,
  },
  counters: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

function getURLParameter(sParam: string) {
  const sPageURL = window.location.search.substring(1);
  const sParameterName = sPageURL.split('=');
  if (sParameterName[0] === sParam) {
    return sParameterName[1];
  }
  return undefined;
}

const baseURL = getURLParameter('baseURL');

const Api = axios.create({
  baseURL: baseURL ?? process.env.REACT_APP_API_URL_PROD_NEW,
});

export const MainPage: FC<IMainPage> = () => {
  const classes = useStyles();
  const [todo, setTodo] = useState<ITodo>({
    id: 0,
    text: '',
    status: false,
    createdAt: '',
    updatedAt: '',
  });
  const [inputValue, setInputValue] = useState<string>('');
  const [editValue, setEditValue] = useState<undefined | string>(undefined);
  const [arrayTodos, setArrayTodos] = useState<ITodo[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [todoForEdit, setTodoForEdit] = useState<ITodo>();
  const [tabValue, setTabValue] = useState<number>(0);
  const [tabValueForServer, setTabValueForServer] = useState<boolean | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const todoPerPage = 5;

  const [countOfReceivingTodos, setCountOfReceivingTodos] = useState<number>(1);
  const [countOfPages, setCountOfPages] = useState<number>(1);
  const [countOfDoneTodos, setCountOfDoneTodos] = useState<number>(0);
  const [countOfUndoneTodos, setCountOfUndoneTodos] = useState<number>(0);
  const [defaultError, setDefaultError] = useState<boolean>(false);
  const [axiosError, setAxiosError] = useState<AxiosError | null>(null);
  const [codeOfAxiosResponse, setCodeOfAxiosResponse] = useState<undefined | number>(undefined);
  const [helperText, setHelperText] = useState<undefined | string>(undefined);
  const [isTodoDontExist, setIsTodoDontExist] = useState<boolean>(true);
  const [isAscendingCreated, setIsAscendingCreated] = useState<boolean>(false);
  const [isAscendingUpdated, setIsAscendingUpdated] = useState<boolean>(false);

  const refreshData = async () => {
    const response = await Api.get('/v1/todo',
      {
        params: {
          page: currentPage,
          perPage: todoPerPage,
          status: tabValueForServer,
        },
      });
    const { data } = response.data;
    setIsTodoDontExist(!data.content.length);
    setArrayTodos(data.content);
    setCountOfReceivingTodos(data.numberOfElements);
    setCountOfUndoneTodos(data.notReady);
    setCountOfDoneTodos(data.ready);
    setDefaultError(false);
    setAxiosError(null);
    setIsAscendingCreated(
      new Date(data.content[0]?.createdAt) < new Date(data.content[1]?.createdAt),
    );
    setIsAscendingUpdated(
      new Date(data.content[0]?.updatedAt) < new Date(data.content[1]?.updatedAt),
    );
  };

  useEffect(() => {
    setCountOfPages(Math.ceil(countOfReceivingTodos / todoPerPage));
  }, [countOfReceivingTodos]);

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setInputValue(value);
  };

  useEffect(() => {
    const trimInputValue = inputValue.trim();
    setTodo({
      ...todo, text: trimInputValue, status: false,
    });
    setCurrentPage(1);
    setTabValue(0);
    setTabValueForServer(undefined);
  }, [inputValue]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await Api.post('v1/todo', todo);
      await refreshData();
      setInputValue('');
    } catch (err) {
      setAxiosError(err);
    }
  };

  const handleToggle = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    try {
      await Api.patch(`v1/todo/status/${id}`, { status: checked });
      await refreshData();
    } catch (err) {
      setDefaultError(true);
    }
  }, [currentPage, tabValue]);

  const handleDelete = async (id: number) => {
    try {
      await Api.delete(`/v1/todo/${id}`);
      await refreshData();
    } catch (err) {
      setDefaultError(true);
    }
  };

  useEffect(() => {
    if (arrayTodos.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [arrayTodos]);

  const handleOpen = (id: number) => {
    const targetTodo = arrayTodos.find((item) => item.id === id);
    setTodoForEdit(targetTodo);
    setEditValue(targetTodo?.text);
    setOpen(true);
  };

  const handleEdit = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEditValue(value);
  };

  const handleClose = () => {
    setOpen(false);
    setAxiosError(null);
  };

  const handleEditSubmit = async () => {
    try {
      await Api.patch(`/v1/todo/text/${todoForEdit?.id}`, { text: editValue });
      await refreshData();
      setOpen(false);
    } catch (err) {
      setAxiosError(err);
    }
  };

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setCurrentPage(1);
    switch (newValue) {
      case 0:
        setTabValueForServer(undefined);
        break;
      case 1:
        setTabValueForServer(true);
        break;
      case 2:
        setTabValueForServer(false);
        break;
      default:
        setTabValueForServer(undefined);
    }
    setTabValue(newValue);
  };

  useEffect(() => {
    (async () => {
      try {
        await refreshData();
      } catch (err) {
        setDefaultError(true);
      }
    })();
  }, [tabValue, currentPage]);

  const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleSelectAll = async () => {
    try {
      await Api.patch('/v1/todo', { status: true });
      await refreshData();
    } catch (err) {
      setDefaultError(true);
    }
  };

  const handleUnselectAll = async () => {
    try {
      await Api.patch('/v1/todo', { status: false });
      await refreshData();
    } catch (err) {
      setDefaultError(true);
    }
  };

  const handleDeleteAllCompleted = async () => {
    try {
      await Api.delete('/v1/todo');
      await refreshData();
    } catch (err) {
      setDefaultError(true);
    }
  };

  const handleSort = (param: string) => {
    switch (param) {
      case 'created':
        setIsAscendingCreated((current) => !current);
        break;
      case 'updated':
        setIsAscendingCreated(true);
        setIsAscendingUpdated((current) => !current);
        break;
      default:
    }
  };

  const currentPageForPagination = currentPage === 0 ? 1 : currentPage;

  useEffect(() => {
    const code = axiosError?.response?.data.statusCode;
    setCodeOfAxiosResponse(code);
    setHelperText(handlerErrors(code));
  }, [axiosError]);

  return (
    <>
      <DialogEdit
        open={open}
        handleClose={handleClose}
        todoForEdit={todoForEdit}
        handleEdit={handleEdit}
        handleEditSubmit={handleEditSubmit}
        axiosError={axiosError}
        codeOfAxiosResponse={codeOfAxiosResponse}
        helperText={helperText}
      />
      <TopBar />
      <form id="form" className={classes.root} onSubmit={handleSubmit}>
        <TextField
          id="form__text-field"
          name="todo"
          placeholder="Add todos!"
          required
          autoFocus
          onChange={handleChangeInput}
          value={inputValue}
          error={!!codeOfAxiosResponse}
          helperText={helperText}
        />
        <Button
          id="form__submit-button"
          type="submit"
        >
          Add todo
        </Button>
      </form>
      <Button
        id="select-all-button"
        variant="contained"
        onClick={handleSelectAll}
        disabled={isTodoDontExist || !countOfUndoneTodos}
      >
        Select all
      </Button>
      <Button
        id="unselect-all-button"
        variant="contained"
        onClick={handleUnselectAll}
        disabled={isTodoDontExist || !countOfDoneTodos}
      >
        Unselect all
      </Button>
      <Button
        id="remove-completed-button"
        variant="contained"
        onClick={handleDeleteAllCompleted}
        disabled={!countOfDoneTodos}
      >
        Remove completed
      </Button>
      <Button
        variant="contained"
        onClick={() => handleSort('created')}
        startIcon={(
          <i className={
            isAscendingCreated
              ? 'sort amount up icon'
              : 'sort amount down icon'
          }
          />
        )}
      >
        Sort by created date
      </Button>
      <Button
        variant="contained"
        onClick={() => handleSort('updated')}
        startIcon={(
          <i className={
            isAscendingUpdated
              ? 'sort amount up icon'
              : 'sort amount down icon'
          }
          />
        )}
      >
        Sort by updated date
      </Button>
      <Paper id="paper" className={classes.rootTab}>
        <Tabs
          id="tabs-list"
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab id="tabs-list__all" label="All" />
          <Tab id="tabs-list__done" label="Done" />
          <Tab id="tabs-list__undone" label="Undone" />
        </Tabs>
      </Paper>
      <div className={classes.todo}>
        <div className={classes.rootTodo}>
          <List id="list-todo">
            {arrayTodos.map((todoItem) => (
              <ListTodos
                key={todoItem.id}
                todo={todoItem}
                handleToggle={handleToggle}
                handleDelete={handleDelete}
                handleOpen={handleOpen}
              />
            ))}
            <Paper id="counters" className={classes.counters}>
              <Typography
                id="counters__active"
              >
                Active:
                {countOfUndoneTodos}
              </Typography>
              <Typography
                id="counters__completed"
              >
                Completed:
                {countOfDoneTodos}
              </Typography>
            </Paper>
          </List>
          <Pagination
            id="pagination"
            page={currentPageForPagination}
            defaultPage={1}
            count={countOfPages}
            onChange={handleChangePagination}
            hideNextButton
            hidePrevButton
          />
        </div>
      </div>
      {defaultError && <Typography> Something went wrong, please try again later </Typography>}
    </>
  );
};

export default MainPage;
